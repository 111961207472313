import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./styles/App.css";
import { fetchMintedCount, getSaleActive } from "./utils/contract-wrappers";
import Nav from "./components/Nav";
import Story from "./components/Story";
import Roadmap from "./components/Roadmap";
import TwitterModal from "./components/TwitterModal";
import CtaActions from "./components/CtaActions";
import Confetti from "react-confetti";

import { ethers } from "ethers";
import contractAbi from "./utils/Blah.abi.json";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const FREE_MINTS = parseFloat(process.env.REACT_APP_FREE_MINTS);
const PRICE = parseFloat(process.env.REACT_APP_PRICE);
const MAX_TOKENS = parseFloat(process.env.REACT_APP_MAX_TOKENS);
const LAUNCHED = process.env.REACT_APP_LAUNCHED === "true";
const GLITCH = process.env.REACT_APP_GLITCH === "true";
const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;
const DISCORD_LAUNCHED = process.env.REACT_APP_DISCORD_LAUNCHED === "true";

console.log(
  "envs",
  CONTRACT_ADDRESS,
  FREE_MINTS,
  PRICE,
  MAX_TOKENS,
  LAUNCHED,
  DISCORD_LINK
);

const App = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [mintedCount, setMintedCount] = useState(0);
  const [saleActive, setSaleActive] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);
  const [mintProgress, setMintProgress] = useState(0);
  const [attemptedConnection, setAttemptedConnection] = useState("");
  const [isTwitterModalOpen, setTwitterModalOpen] = useState(false);
  const [isGlitching, setGlitching] = useState(false);

  const [mintedOnce, setMintedOnce] = useState(false);

  const soldOut = useMemo(() => {
    return mintedCount >= MAX_TOKENS;
  }, [mintedCount]);

  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    }
    /*
     * Check if we're authorized to access the user's wallet
     */
    const accounts = await ethereum.request({
      method: "eth_accounts",
    });

    /*
     * User can have multiple authorized accounts, we grab the first one if its there
     */
    if (accounts && accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account);
    }
  };

  const updateMintedCount = useCallback(async () => {
    const count = await fetchMintedCount();
    setMintedCount(count);
  }, [setMintedCount]);

  const updateSaleActive = useCallback(async () => {
    const saleActive = await getSaleActive();
    setSaleActive(saleActive);
  }, [setSaleActive]);

  const openModal = useCallback(
    (e) => {
      if (!DISCORD_LAUNCHED) {
        e.preventDefault();
        setTwitterModalOpen(true);
      }
    },
    [setTwitterModalOpen]
  );

  const closeModal = () => {
    setTwitterModalOpen(false);
  };

  useEffect(() => {
    if (!LAUNCHED) {
      return;
    }
    // site is launched
    checkIfWalletIsConnected();
  }, []);

  useEffect(() => {
    if (!currentAccount || !LAUNCHED) {
      return;
    }
    // user is logged in and site is launched
    updateMintedCount();
    updateSaleActive();
  }, [currentAccount, updateMintedCount, updateSaleActive]);

  useEffect(() => {
    if (!currentAccount || !LAUNCHED) {
      return;
    }

    // user is logged in and site is launched
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const connectedContract = new ethers.Contract(
      CONTRACT_ADDRESS,
      contractAbi.abi,
      provider
    );

    connectedContract.on("NFTMinted", (from, quantity) => {
      console.log("NFTMinted event", from, quantity.toNumber());
      // probably should increment instead of fetching again but whatever
      updateMintedCount();
    });

    return () => {
      // clean up function
      connectedContract.removeAllListeners("NFTMinted");
    };
  }, [currentAccount, updateMintedCount, mintedCount]);

  // for effects after first mint
  useEffect(() => {
    if (!LAUNCHED) {
      return;
    }
    let startGlitch, endGlitch;

    if (mintedOnce) {
      // only happens after the first mint of this page load
      // confetti, and after 3 seconds, no more confetti and start glitching for 20s
      setIsConfettiActive(true);
      startGlitch = setTimeout(() => {
        setIsConfettiActive(false);
        setGlitching(GLITCH && true);
        endGlitch = setTimeout(() => {
          setGlitching(false);
        }, 20 * 1000);
      }, 3000);
    }
    return () => {
      clearTimeout(startGlitch);
      clearTimeout(endGlitch);
    };
  }, [mintedOnce, setIsConfettiActive, setGlitching]);

  // Start first glitch after 80 seconds
  // Glitch for 20 seconds
  // No glitch for 60 seconds
  // Glitch for 20 seconds
  // No glitch for 60 seconds...
  useEffect(() => {
    if (!LAUNCHED) {
      return;
    }

    let timeoutEnd;
    const intervalStart = setInterval(() => {
      console.log("start glitch");
      setGlitching(GLITCH && true);
      timeoutEnd = setTimeout(() => {
        console.log("end glitch");
        setGlitching(false);
      }, 20 * 1000);
    }, (60 + 20) * 1000);
    return () => {
      clearInterval(intervalStart);
      clearTimeout(timeoutEnd);
    };
  }, []);

  return (
    <div className="App">
      <Confetti
        numberOfPieces={100}
        run={true}
        gravity={0.045}
        recycle={isConfettiActive}
        colors={["#e8bc64", "#d4a13b", "#d4b013", "#f7e283"]}
      />
      <Nav openModal={openModal} />
      {isTwitterModalOpen && <TwitterModal closeModal={closeModal} />}
      <section className="header-container">
        <div className="content">
          <div className="flex-container">
            <div className="flex-pad-right">
              <div className="hero-container">
                <h2
                  className={`gradient-text ${isGlitching ? "paths" : ""} ${
                    mintProgress === 2 ? "active" : ""
                  }`}
                  data-text="Pawsperity Cats"
                >
                  <span>Pawsperity Cats</span>
                </h2>
              </div>
              <p className="sub-text">
                Meet your one-of-a-kind, lucky Pawsperity Cat.
                <br />
                {MAX_TOKENS.toLocaleString()} Pawsperity Cats at{" "}
                {PRICE.toLocaleString()}Ξ each. Minting is live!
                <br />
                <br />
                Each NFT you mint is an entry to giveaways including up to{" "}
                <a href="https://opensea.io/collection/azuki" target="_blank">
                  3 Azukis
                </a>{" "}
                and{" "}
                <a
                  href="https://opensea.io/collection/karafuru"
                  target="_blank"
                >
                  3 Karafurus
                </a>
                *
              </p>
              <CtaActions
                currentAccount={currentAccount}
                setCurrentAccount={setCurrentAccount}
                mintedCount={mintedCount}
                setMintProgress={setMintProgress}
                setAttemptedConnection={setAttemptedConnection}
                soldOut={soldOut}
                saleActive={saleActive}
                mintProgress={mintProgress}
                setMintedOnce={setMintedOnce}
              />
              <br />
              <br />
              {currentAccount && !saleActive && (
                <small>
                  <em>
                    Sale not live yet. Come hang out on our{" "}
                    <a href={DISCORD_LINK} target="_blank" onClick={openModal}>
                      Discord
                    </a>{" "}
                    in the meantime!
                  </em>
                </small>
              )}
              {mintProgress ? (
                <small>
                  <em>
                    Minting in progress...{" "}
                    {mintProgress === 2 && "Waiting for confirmations..."}
                  </em>
                </small>
              ) : (
                currentAccount &&
                saleActive && (
                  <small>
                    <em>
                      {mintedCount.toLocaleString()}/
                      {MAX_TOKENS.toLocaleString()} Pawsperity Cats minted!
                    </em>
                  </small>
                )
              )}
              {attemptedConnection && attemptedConnection === "no wallet" && (
                <small>
                  <strong>Uh oh!</strong>
                  <br />
                  Seems like we couldn't connect! Make sure you've enabled{" "}
                  <a href="https://metamask.io/download.html" target="_blank">
                    Metamask
                  </a>{" "}
                  on this browser.
                </small>
              )}
              {attemptedConnection &&
                attemptedConnection === "User rejected the request." && (
                  <small>
                    <strong>Seems like the connection was canceled!</strong>
                    <br />
                    If you'd like to meet your Pawsperity Cat, refresh the page
                    and try again.
                  </small>
                )}
            </div>
            <div>
              <div
                className={`hero-image ${
                  isGlitching ? "banner-flicker" : "wiggle"
                }`}
                onMouseEnter={() => {
                  if (!mintProgress) setIsConfettiActive(true);
                }}
                onMouseLeave={() => setIsConfettiActive(false)}
              />
            </div>
          </div>
        </div>
      </section>
      <Story openModal={openModal} />
      <Roadmap />
      <section className="gallery-container"></section>
      <section className="footer-container">
        Pawsperity Cats was created with 💛 and fortune by friends around the
        world. Join our community on{" "}
        <a href={DISCORD_LINK} target="_blank" onClick={openModal}>
          Discord
        </a>{" "}
        and follow us on{" "}
        <a href="https://twitter.com/pawsperitycats" target="_blank">
          Twitter
        </a>
        !
      </section>
      {isGlitching && (
        <section>
          <h1 className="robo flicker">
            <pre>
              <code>
                63 68 61 31 6e 20 6d 31 6e 65 20 6e 30 77 2e 20 6d 65 30 77 2e
              </code>
            </pre>
          </h1>
        </section>
      )}
    </div>
  );
};

export default App;
