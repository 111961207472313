import React from "react";
import catLogo from "../assets/whitecat.png";
import discordLogo from "../assets/discord.svg";
import twitterLogo from "../assets/twitter.svg";

const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;

const Nav = (props) => {
  console.log("Nav");
  return (
    <div className="nav-container">
      <div className="nav-content">
        <a href="#">
          <div className="nav-icon">
            <img src={catLogo} />
          </div>
        </a>
        <a href={DISCORD_LINK} target="_blank" onClick={props.openModal}>
          <div className="nav-icon">
            <img src={discordLogo} />
          </div>
        </a>
        <a href="https://twitter.com/pawsperitycats" target="_blank">
          <div className="nav-icon">
            <img src={twitterLogo} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default React.memo(Nav);
