import twitterLogo from "../assets/twitter.svg";

const TwitterModal = (props) => {
  return (
    <section className="modal-container">
      <div className="panel">
        <div className="modal-close" onClick={props.closeModal}></div>
        <h2>You're almost there!</h2>
        Our Discord is still in <strong>early access mode</strong>, but don't
        fret! Check out our{" "}
        <a href="https://twitter.com/pawsperitycats" target="_blank">
          Twitter
        </a>{" "}
        for first access to exclusive invite links and other great updates.
        <br />
        <br />
        Plus, a mysterious cat once told me that following and liking the
        Pawsperity Cats' Twitter posts could help one win a special prize...
        <br />
        <br />
        <div className="centered">
          <a href="https://twitter.com/pawsperitycats" target="_blank">
            <button className="cta-button twitter-button">
              Visit our Twitter <img src={twitterLogo} height="18" />
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default TwitterModal;
