const NotConnectedContainer = (props) => {
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        props.attemptConnection("no wallet");
        return;
      }

      /*
       * Fancy method to request access to account.
       */
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      /*
       * Boom! This should print out public address once we authorize Metamask.
       */
      console.log("Connected", accounts[0]);
      props.onAccountChange(accounts[0]);
      props.attemptConnection("connected");
    } catch (error) {
      console.error(error);
      props.attemptConnection(error.message);
    }
  };

  return (
    <button
      onClick={connectWallet}
      className="cta-button connect-wallet-button"
      disabled={!props.launched}
    >
      Connect your wallet
    </button>
  );
};

export default NotConnectedContainer;
