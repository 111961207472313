import { ethers } from "ethers";
import contractAbi from "../utils/Blah.abi.json";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const PRICE = parseFloat(process.env.REACT_APP_PRICE);
const TARGET_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

export async function fetchMintedCount() {
  try {
    const { ethereum } = window;
    if (ethereum) {
      let chainId = await ethereum.request({ method: "eth_chainId" });
      console.log("Connected to chain " + chainId);

      if (chainId !== TARGET_CHAIN_ID) {
        alert("fetchMintedCount you are not connected to " + TARGET_CHAIN_ID);
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        signer
      );

      let count = await connectedContract.getSupply();
      console.log(`Count ${count}, number ${count.toNumber()}`);
      return count.toNumber();
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSaleActive() {
  try {
    const { ethereum } = window;
    if (ethereum) {
      let chainId = await ethereum.request({ method: "eth_chainId" });
      console.log("Connected to chain " + chainId);

      if (chainId !== TARGET_CHAIN_ID) {
        alert("getSaleActive you are not connected to " + TARGET_CHAIN_ID);
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        signer
      );

      let resp = await connectedContract.saleIsActive();
      console.log("Checking sale active...please wait.");

      console.log(`getSaleActive got back ${resp} (${typeof resp})`);
      return resp;
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  } catch (error) {
    console.log(error);
  }
}

// quantity: mintQuantity,
// free: mintedCount < FREE_MINTS,
// setProgress: setIsConfirming,
// errorCallback
export async function attemptMint(params) {
  console.log("attempting mint", params);
  try {
    const { ethereum } = window;

    if (ethereum) {
      params.errorCallback("");
      params.setProgress(1);
      let chainId = await ethereum.request({ method: "eth_chainId" });
      console.log("Connected to chain " + chainId);

      if (chainId !== TARGET_CHAIN_ID) {
        alert("attemptMint you are not connected to " + TARGET_CHAIN_ID);
        return;
      }

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        contractAbi.abi,
        signer
      );
      console.log("Going to pop wallet now to pay gas...");

      let nftTxn;

      if (params.free) {
        nftTxn = await connectedContract.freeMint(params.quantity, {});
      } else {
        const price = PRICE * params.quantity;
        const options = { value: ethers.utils.parseEther(price.toString()) };
        console.log(options);
        nftTxn = await connectedContract.publicMint(params.quantity, options);
      }

      console.log("Minting...please wait.");
      params.setProgress(2);
      await nftTxn.wait();

      console.log(
        `Minted, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`
      );
      params.setMintedOnce(true);
      params.setProgress(0);
    } else {
      console.log("Ethereum object doesn't exist!");
    }
  } catch (error) {
    params.setProgress(0);
    console.log("error on mint");
    console.log(error);

    if (error instanceof Error) {
      console.log("instanceof");
      params.errorCallback(error.code);
    }
  }
}
