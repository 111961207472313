import React from "react";
import fortuneCatImage from "../assets/fortune_cat.png";

const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;
const MAX_TOKENS = parseFloat(process.env.REACT_APP_MAX_TOKENS);

const Story = (props) => {
  return (
    <section className="story-container">
      <div className="panel">
        <img src={fortuneCatImage} height="100" />
        <h2>The Pawsperity Story</h2>
        Pawsperity cats have a long history of bringing fortune to their owners,
        but waving your paw can get tiring real quick.
        <br />
        <br />
        One day, a Pawsperity cat met an influencer cat friend who had become
        famous through... something called a "meme"? Inspired, the Pawsperity
        cat jumped on their owner's laptop (while they were working), and soon
        learned to paw for attention across the globe. If you take a Pawsperity
        cat home, it's said that fortune will wave your way.
        <br />
        <hr />
        <strong>Pawsperity Cats</strong> is a NFT collection comprising{" "}
        {MAX_TOKENS.toLocaleString()} digitally-drawn and procedurally generated
        Pawsperity Cats, bringing good fortune and adorable charm to everyone on
        the Ethereum blockchain!
        <br />
        <br />
        <div className="centered">
          <a href={DISCORD_LINK} target="_blank" onClick={props.openModal}>
            <button className="cta-button discord-button">
              Join our Discord
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Story);
