import React from "react";
import redEnvelopeImage from "../assets/red_envelope.png";

const MAX_TOKENS = parseFloat(process.env.REACT_APP_MAX_TOKENS);

const Roadmap = (props) => {
  return (
    <section className="roadmap-container">
      <div className="panel">
        <img src={redEnvelopeImage} height="100" />
        <h2>Pawsperity Perks</h2>
        By minting a Pawsperity Cat, you are automatically entered in our
        giveaway raffles!
        <br />
        <br />
        At every major Auspicious number minted, we will be randomly selecting
        one or more Pawsperity Cat holders as prizewinners! Winners will be
        airdropped notable up and coming NFTs - think{" "}
        <a href="https://opensea.io/collection/azuki" target="_blank">
          Azukis
        </a>
        ,{" "}
        <a href="https://opensea.io/collection/karafuru" target="_blank">
          Karafuru
        </a>
        ,{" "}
        <a href="https://zipcy.io/" target="_blank">
          Zipcy
        </a>
        , or{" "}
        <a href="https://opensea.io/collection/phantabear" target="_blank">
          Phanta Bear
        </a>
        *. The higher the Auspicious Number, the higher the floor price of the
        NFT 👀
        <br />
        <br />
        In addition to the giveaway at mint out, we will announce a total of{" "}
        <strong>3 Auspicious numbers</strong>, and every Pawsperity Cat counts
        as one raffle ticket to win the giveaways. Will you be the lucky
        Pawsperity Cat owner?
        <br />
        <div className="raffle-section">
          <div className="raffle-preview">
            <div className="airdrop t-4"></div>
            <div className="raffle-text">
              <em>
                <a
                  href="https://opensea.io/collection/karafuru"
                  target="_blank"
                >
                  Karafuru x 2 *
                </a>{" "}
                giveaway at
              </em>
              <h3>2288</h3>
            </div>
          </div>
          <div className="raffle-preview">
            <div className="airdrop question-mark"></div>
            <div className="raffle-text">
              <em>Next giveaway at</em>
              <h3>3388</h3>
            </div>
          </div>
          <div className="raffle-preview">
            <div className="airdrop t-5"></div>
            <div className="raffle-text">
              <em>
                <a href="https://opensea.io/collection/azuki" target="_blank">
                  Azuki x 2 *
                </a>{" "}
                final giveaway at
              </em>
              <h3>{MAX_TOKENS}</h3>
            </div>
          </div>
        </div>
        <br />
        <small>
          * Subject to change depending on floor price of each project
        </small>
        <hr />
        The perks don't stop there!
        <br />
        <br />
        After minting has ended, we will actively give back to the community
        with the royalties that are deposited into the{" "}
        <strong>🐱 Pawsperity Kitty Bank 🐱</strong>.
        <br />
        <br />
        Every so often, we will ask our community to vote for a{" "}
        <strong>🧧 Pawsperity Giveaway 🧧</strong> from a list of hot NFT
        projects (curated by the team). Using royalty funds from the Pawsperity
        Kitty Bank, we will purchase an NFT from the selected project and gift
        it to a lucky winner! Each Pawsperity Cat you own represents a chance to
        win.
      </div>
    </section>
  );
};

// prevents double render when hovering over the bobbing cat
export default React.memo(Roadmap);
