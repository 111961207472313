import React, { useMemo, useState } from "react";
import NotConnectedContainer from "./NotConnectedContainer";

import { attemptMint } from "../utils/contract-wrappers";

const FREE_MINTS = parseFloat(process.env.REACT_APP_FREE_MINTS);
const LAUNCHED = process.env.REACT_APP_LAUNCHED === "true";

const CtaActions = ({
  currentAccount,
  setCurrentAccount,
  mintedCount,
  setMintProgress,
  setAttemptedConnection,
  soldOut,
  saleActive,
  mintProgress,
  setMintedOnce,
}) => {
  const [mintQuantity, setMintQuantity] = useState(1);
  const [mintError, setMintError] = useState("");

  const disableMintButton = false;
  // useMemo(() => {
  //   return !saleActive || !LAUNCHED || mintProgress;
  // }, [saleActive, mintProgress]);

  const mintQuantityOptions = useMemo(() => {
    const MINT_TXN_LIMIT = mintedCount < FREE_MINTS ? 5 : 10;
    let items = [];
    for (let i = 1; i <= MINT_TXN_LIMIT; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }, [mintedCount]);

  const mintWrapper = async () => {
    const MINT_TXN_LIMIT = mintedCount < FREE_MINTS ? 5 : 10;
    if (mintQuantity > MINT_TXN_LIMIT) {
      return;
    }

    if (mintQuantity < 1) {
      return;
    }

    await attemptMint({
      quantity: parseInt(mintQuantity),
      free: mintedCount < FREE_MINTS,
      setProgress: setMintProgress,
      errorCallback: setMintError,
      setMintedOnce: setMintedOnce,
    });
  };

  // if not launched, show no actions
  if (!LAUNCHED) {
    return (
      <button
        disabled={true}
        className="cta-button connect-wallet-button not-yet"
      >
        Coming soon
      </button>
    );
  }

  // when launched
  if (soldOut) {
    return (
      <button disabled className="cta-button">
        We're sold out!
      </button>
    );
  } else if (!currentAccount) {
    return (
      <NotConnectedContainer
        onAccountChange={setCurrentAccount}
        attemptConnection={setAttemptedConnection}
        launched={LAUNCHED}
      />
    );
    // } else if (!saleActive) {
    //   return (
    //     <button disabled className="cta-button not-yet">
    //       Minting hasn't started yet
    //     </button>
    //   );
  } else {
    // account connected
    // if sale is inactive, the button is disabled
    return (
      <>
        <select
          className="cta-select"
          value={mintQuantity}
          onChange={(e) => setMintQuantity(e.target.value)}
          disabled={mintProgress}
        >
          <option disabled>Choose quantity</option>

          {mintQuantityOptions}
        </select>
        <button
          onClick={mintWrapper}
          disabled={disableMintButton}
          className="cta-button connect-wallet-button"
        >
          Mint {mintQuantity} Cat{mintQuantity > 1 ? "s" : ""}
        </button>
        {mintError === "INSUFFICIENT_FUNDS" && (
          <div className="mint-error">
            <strong>Oops!</strong> You have insufficient funds in your ETH
            wallet.
          </div>
        )}
        {mintError === "UNPREDICTABLE_GAS_LIMIT" && (
          <div className="mint-error">
            <strong>Oops!</strong> The blockchain could not estimate gas for
            this transaction. Try again with a different quantity?
          </div>
        )}
      </>
    );
  }
};

export default React.memo(CtaActions);
